export default {
    name: 'header',
    component() {
        return {
            // open: false,
            scrollTop: 0,
            visible: false,
            sticky: false,
            bg: false,
            offset: 0,
            offset2: 90,
            lastScrollTop: 0,
            subNav: '',
            subNavChild: '1',
            filter: false,
            headerHeight: 0,
            // wasSticky: false,
            itemHover: false,
            homepage: false,
            // popup: false,
            selected: "",
            color: '',

            init() {
                // const header = document.querySelector(".header");
                if (document.querySelector('body').classList.contains('index-template')) {
                    this.offset2 = window.innerHeight
                }

                this.stickyNav();
                window.addEventListener("scroll", () => {
                    this.stickyNav();
                });

                const colorParam = this.getURLParameter('color');
                if (colorParam !== '') {
                    this.color = colorParam;
                } else {
                    this.color = '';
                }
                // console.log(this.color);

                setTimeout(() => {                    
                    const navs = document.querySelectorAll('[data-nav]');
                    navs.forEach(nav => {
                        const index = nav.dataset.nav;
                        const left = nav.offsetLeft;
                        document.querySelector(`[data-subnav="${index}"]`).style.paddingLeft = `${left}px`;
                    });
                }, 200);
            },

            getURLParameter(name) {
                name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
                var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
                var results = regex.exec(window.location.search);
                return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
            },

            stickyNav() {
                if (this.subNav !== '') return;

                const top =
                    window.scrollY ||
                    window.scrollTop ||
                    document.getElementsByTagName("html")[0].scrollTop;
                // let st = window.pageYOffset || document.documentElement.scrollTop;
        
                if (top > this.offset2) {
                    this.bg = true;
                    this.sticky = true;
                } else if (top > this.offset) {
                    this.sticky = true;
                    this.bg = false;
                } else {
                    this.sticky = false;
                    this.bg = false;
                }
            },

            getMobileFilterTop(el) {
                if(window.innerWidth < 1000) {
                    setTimeout(() => { 
                        let filterHeight = document.querySelector('[data-filter-bar]').getBoundingClientRect().height;
                        let headerHeight = document.querySelector('header').getBoundingClientRect().height;
                        let totalHeight = filterHeight + headerHeight;
                        el.style.top=`${totalHeight}px`;

                        let filter = document.querySelector("[data-filter]");
                        filter.style.maxHeight = `calc(100svh - ${totalHeight}px)`;

                    }, 100);

                    window.addEventListener("resize", () => {
                        setTimeout(() => { 
                            let filterHeight = document.querySelector('[data-filter-bar]').getBoundingClientRect().height;
                            let headerHeight = document.querySelector('header').getBoundingClientRect().height;
                            let totalHeight = filterHeight + headerHeight;
                            el.style.top=`${totalHeight }px`;

                            let filter = document.querySelector("[data-filter]");
                            filter.style.maxHeight = `calc(100svh - ${totalHeight}px)`;
                        }, 100);
                    });
                }
            }
        }
    }
}