export default {
    name: 'instagram',
    component() {
        return {
            // results: [],
            // accessToken: "IGQWRQRlh5dkVXRlZAlRldlSUcyWHZA4SV9aeml5Y0pETWVSZAjY5RG5tUWhZAQzRsaVBjSTBsai1pcG5HZAThSNkVFc1JYWU5jaXV6YmF4bGRCbVdjT2tDeVlBbmxpNFVxNHhWUE5WMmtmSzhfX1RxenNYSnpwQUV0VHcZD",
            // accessTokenMel: "IGQWRQb0JVQzROSDZAkcG9KYW9JOWc1TU1RbUZAGYnR2MXRna0FNczh6SUJJd2NTN0pEMjZAIYTNTYzhmQWJrZA2ZATdzdieXUwNmRmb2NRaE1xOTkwT1hrLS1FS0MwVFE2ZADl5T2syYlN2Nkt6RDdWWWhtQ0RZAT3hRUWcZD",
           
            // init() {
            //     // this.getInstagramFeed();
            // },

            // getInstagramFeed() {
            //     fetch(`https://graph.instagram.com/v12.0/me/media?fields=id,media_type,media_url,permalink,timestamp&limit=10&access_token=${this.accessToken}`)
            //     .then(response => response.json())
            //     .then(data => {
            //         this.results = data.data;
            //     })
            //     .catch(error => console.error(error));
            // },

            // getInstagramFeedMel() {
            //     fetch(`https://graph.instagram.com/v12.0/me/media?fields=id,media_type,media_url,permalink,timestamp&limit=10&access_token=${this.accessTokenMel}`)
            //     .then(response => response.json())
            //     .then(data => {
            //         this.results = data.data;
            //     })
            //     .catch(error => console.error(error));
            // }
        }
    }
}