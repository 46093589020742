import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default {
  name: 'parallax',
  component() {
      return {
        yEnd: "",
        yStart: "",
        start: "top 100%",
        end: "bottom 0%",
        mobile: true,
    
        setParallax() {
          // console.log(this.mobile);
          if (!this.mobile && window.innerWidth <= 1000) return;

          const els = this.$root.querySelectorAll('[data-el]');

          els.forEach(el => {
            const anim = gsap.timeline({
                scrollTrigger: {
                    trigger: this.$root,
                    start: this.start,
                    end: this.end,
                    scrub: true,
                },
            })
            
            if (this.yStart !== '') {
              anim.from(el, {
                ease: "none",
                translateY: this.yStart,
              });
            }
            
            anim.to(el, {
                ease: "none",
                translateY: this.yEnd,
            });
          })

        }
    }
  }
}