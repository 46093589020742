import Swiper from "../../swiper";
import { Navigation, Pagination, Autoplay, A11y, FreeMode, Mousewheel } from "../../swiper/modules";

export default {
    name: 'slider',
    component() {
      return {
        init() {
        },

        initSlider() {
            const slider = this.$root.querySelector(".swiper");
            new Swiper(slider, {
                modules: [Autoplay, A11y, Navigation, Mousewheel, FreeMode],
                direction: "horizontal",
                loop: true,
                slidesPerView: 1,
                allowTouchMove: true,
                disableOnInteraction: false,
                spaceBetween: 10,
                breakpoints: {
                    480: {
                        slidesPerView: 2,
                        spaceBetween: 10,
                    },
                    750: {
                        slidesPerView: 3,
                        spaceBetween: 15,
                    },
                    1000: {
                        slidesPerView: 4,
                        spaceBetween: 30,
                    },
                },
                freeMode: true,
                mousewheel: {
                    enabled: true,
                    eventsTarget: 'main'
                },
                navigation: {
                    nextEl: slider
                        .closest(".slider--item")
                        .querySelector("[data-slider-btn-next]"),
                    prevEl: slider
                        .closest(".slider--item")
                        .querySelector("[data-slider-btn-prev]"),
                },
            });
        },    

        initSliderCenter() {
            const slider = this.$root.querySelector(".swiper");
            new Swiper(slider, {
                modules: [Autoplay, A11y, Mousewheel, FreeMode],
                direction: "horizontal",
                loop: false,
                slidesPerView: 1,
                allowTouchMove: true,
                disableOnInteraction: false,
                spaceBetween: 10,
                centeredSlides: false,
                initialSlide: 0,
                freeMode: true,
                mousewheel: {
                    enabled: true,
                    forceToAxis: true,
                    // eventsTarget: 'main'
                },
                breakpoints: {
                    480: {
                        slidesPerView: 2,
                        spaceBetween: 10,
                        centeredSlides: false,
                        initialSlide: 0,
                    },
                    750: {
                        slidesPerView: 3,
                        spaceBetween: 15,
                        centeredSlides: false,
                        initialSlide: 0,
                    },
                    1000: {
                        slidesPerView: 4,
                        spaceBetween: 30,
                        centeredSlides: true,
                        initialSlide: 1,
                    },
                },
            });
        },    

        initSliderBlocks() {
            const slider = this.$root.querySelector(".swiper");
            new Swiper(slider, {
                modules: [Autoplay, A11y, Navigation, FreeMode],
                direction: "horizontal",
                loop: false,
                slidesPerView: 1,
                allowTouchMove: true,
                disableOnInteraction: false,
                freeMode: true,
                spaceBetween: 10,
                breakpoints: {
                    480: {
                        slidesPerView: 2,
                        spaceBetween: 10,
                    },
                    750: {
                        slidesPerView: 3,
                        spaceBetween: 15,
                    },
                    1000: {
                        slidesPerView: 4,
                        spaceBetween: 30,
                    },
                },
                navigation: {
                    nextEl: slider
                        .closest(".slider--item")
                        .querySelector("[data-slider-btn-next]"),
                    prevEl: slider
                        .closest(".slider--item")
                        .querySelector("[data-slider-btn-prev]"),
                },
            });
        },    

        initSliderProductImgs() {
          const slider = this.$root.querySelector(".swiper");
          new Swiper(slider, {
              modules: [Pagination, Autoplay, A11y],
              direction: "horizontal",
              loop: true,
              slidesPerView: 1,
              allowTouchMove: true,
              disableOnInteraction: false,
              spaceBetween: 10,
            //   navigation: {
            //       nextEl: slider
            //           .closest(".slider--item")
            //           .querySelector("[data-slider-btn-next]"),
            //       prevEl: slider
            //           .closest(".slider--item")
            //           .querySelector("[data-slider-btn-prev]"),
            //   },
              pagination: {
                el: slider
                    .closest(".slider--item")
                    .querySelector(".swiper-pagination"),
                clickable: true,
            },
          });
        },
      }
    }
  }