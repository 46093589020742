export default {
    name: 'home',
    component() {
        return {
            bodyColor: "",
            textColor: "",
            footerLogoColor: "",
            footerInvestWhite: false,

            init() {

                
                let body = document.querySelector('body');
                let footer = document.querySelector("[data-footer]");

                if (body.classList.contains('index-template')) {
                    document.querySelector("header").style.color='white';
                    document.querySelector("header").style.position="fixed";
                    document.querySelector("header").style.backgroundColor = "transparent";

                    document.querySelector(".header--logo").style.opacity="0%"; 
                    document.querySelector(".header--logo").classList.add("homepage"); 
                }

                if(document.querySelector("[data-body-color]") != null && document.querySelector("[data-body-color]").dataset.bodyColor != 'rgba(0,0,0,0)') { 
                    document.documentElement.style.setProperty('--backgroundColor',document.querySelector("[data-body-color]").dataset.bodyColor);
                }; 
                if(document.querySelector("[data-text-color]") != null && document.querySelector("[data-text-color]").dataset.textColor != 'rgba(0,0,0,0)') {  
                    document.documentElement.style.setProperty('--textColor',document.querySelector("[data-text-color]").dataset.textColor);
                };

                if(document.querySelector("[data-body-color]").dataset.headerAbsolute !== 'false') {
                    let menuStripes = document.querySelectorAll(".open--menu");
                    menuStripes.forEach((stripe) => {
                        stripe.style.backgroundColor = 'white';
                    })
                }

                if (document.querySelector("[data-body-color]") != null) {
                    this.bodyColor = document.querySelector("[data-body-color]").dataset.bodyColor;
                    if(this.bodyColor != 'rgba(0,0,0,0)') {
                        body.style.backgroundColor = `${this.bodyColor}`;
                        document.querySelector('main').style.backgroundColor = `${this.bodyColor}`;
                        footer.style.color = `${this.bodyColor}`;
                        // console.log(this.bodyColor);

                        // document.querySelector("[data-investment-black]").classList.add("opacity-0");
                        // document.querySelector("[data-investment-white]").classList.remove("opacity-0");
                    }
                };

                if (document.querySelector("[data-text-color]") != null) {
                    this.textColor = document.querySelector("[data-text-color]").dataset.textColor;
                    if(this.textColor != 'rgba(0,0,0,0)') {
                        body.style.color = `${this.textColor}`;
                        footer.style.backgroundColor = `${this.textColor}`;
                    }
                };

                if (document.querySelector("[data-footer-logo-color]") != null) {
                    this.footerLogoColor = document.querySelector("[data-footer-logo-color]").dataset.footerLogoColor;
                    if(this.footerLogoColor != 'rgba(0,0,0,0)') {
                        let footerLogo = document.querySelector("[data-footer-logo]");
                        footerLogo.style.color = `${this.footerLogoColor}`;
                    }
                };

                if (document.querySelector("[data-footer-invest-white]") != null) {
                    this.footerInvestWhite = document.querySelector("[data-footer-invest-white]").dataset.footerInvestWhite;

                    if(this.footerInvestWhite == "true") {
                        document.querySelector("[data-investment-black]").classList.add("opacity-0");
                        document.querySelector("[data-investment-white]").classList.remove("opacity-0");
                    } else {
                        document.querySelector("[data-investment-black]").classList.remove("opacity-0");
                        document.querySelector("[data-investment-white]").classList.add("opacity-0");
                    }
                };
            },
        }
    }
}