export default {
    name: 'logo',
    component() {
        return {
          init() {
            const logo = this.$refs.logo;
            const halfHeight = window.innerHeight / 2 - 20;

            document.addEventListener('scroll', () => {

              const isLocked = document.querySelector('body').getAttribute("style").indexOf("overflow:");
              if (isLocked >= 0) return;

              const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
              const percentage = scrollTop / (window.innerHeight / 2) * 100;
              const percentage2 = scrollTop / (window.innerHeight) * 100;
              
              const top = (percentage * (halfHeight - 0) / 100) + 0;
              const scale = (percentage * (0.5 - 1) / 100) + 1;

              if (percentage >= 0 && percentage <= 100) {
                logo.style.transform = `translateY(-${top}px) scale(${scale})`;
              } else if (percentage > 100) {
                if (window.innerWidth > 1000) {
                  logo.style.transform = `translateY(-50svh) translateY(30rem) scale(0.5)`;
                } else {
                  logo.style.transform = `translateY(-50svh) translateY(20rem) scale(0.5)`;
                }
              }

              if (percentage2 >= 0 && percentage2 <= 100) {
                logo.querySelector('svg').classList.add('text-white');
                logo.querySelector('svg').classList.remove('text-black');
                logo.classList.remove('pointer-events-auto');
              } else if (percentage2 > 100) {
                logo.querySelector('svg').classList.remove('text-white');
                logo.querySelector('svg').classList.add('text-black');
                logo.classList.add('pointer-events-auto');
              }

                // console.log(top);
            });

          }
        }
  }
}